<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  async mounted() {
    
  },
  methods: {
  }
};
</script>


<style src="./styles/app.scss" lang="scss"/>
